body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.main-container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

* {
	box-sizing: border-box;
}

.img-container {
	width: 100%;
	height: 120px;
	overflow: hidden;
}

.loader {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 9999999999999;
}

/* .main {
	flex-grow: 1;
	background-color: #eeeeee;
	width: 100%;
	position: relative;
} */

.loadingMain {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
}
