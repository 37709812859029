.container {
	max-width: 100%;
	width: 640px;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	padding: 40px;
}

.card {
	padding: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	min-width: 100%;
	position: relative;
}

@media only screen and (max-width: 500px) {
	.container {
		padding: 0px;
		height: auto;
	}
}
