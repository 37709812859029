.mainPNF {
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #dedede;
	flex-direction: column;
}

.mainPNF h1 {
	font-size: 48px;
	color: #ababab;
	text-align: center;
}

.mainPNF h2 {
	font-size: 24px;
	color: #ababab;
	text-align: center;
}
