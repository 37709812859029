.character {
	border: 1px solid rgba(0, 0, 0, 0.23);
	border-radius: 3px;
	color: currentColor;
	font-size: 18px;
}

.character--inactive {
	background-color: white;
	color: rgba(0, 0, 0, 0.87);
}

.character--selected {
	border: 2px solid #2ca58d;
	outline: unset;
}
