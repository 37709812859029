.footer-cart {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	padding-top: 10px;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	background-color: white;
	box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 0.2);
}

.cart-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-left: 8px;
	padding-right: 8px;
	padding-top: 6px;
	padding-bottom: 6px;
	width: 64px;
}

.fullscreen-dialog {
	background-color: white;
	padding: 24px;
}

.flex-row {
	display: flex;
	flex-direction: row;
}

.align-center {
	display: flex;
	align-items: center;
}

.space-between {
	display: flex;
	justify-content: space-between;
}
