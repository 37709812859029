.overlay-qr-code {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	min-height: 100vh;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.overlay-internal-square {
	width: 290px;
	height: 290px;
	background-color: transparent;
	border: 5px solid white;
	border-radius: 10px;
	box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.8);
}

.text-qr-code-reader {
	color: white;
	position: relative;
	font-size: 16px;
	margin-bottom: 20px;
}

.switch-cam {
	position: absolute;
	bottom: 20px;
	right: 20px;
}

/* This used to work for the parent element of button divs */
/* But it does not work with newer browsers, the below doesn't hide the play button parent div */

*::-webkit-media-controls-panel {
	display: none !important;
	-webkit-appearance: none;
}

/* Old shadow dom for play button */

*::-webkit-media-controls-play-button {
	display: none !important;
	-webkit-appearance: none;
}

/* New shadow dom for play button */

/* This one works! */

*::-webkit-media-controls-start-playback-button {
	display: none !important;
	-webkit-appearance: none;
}
